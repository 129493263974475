<template>
    <LayoutEtapas>
        <div class='formgrid grid p-fluid'>
            <div class='field col-3'>
                <BInputNumeric v-model='valorReceita'
                               :desabilita='desabilita'
                               :mensagemErro="v$.valorReceita.$silentErrors[0]?.$message || ''"
                               :temErro='v$.valorReceita.$error'
                               label='Recebimento de Receitas'
                               required @blur='v$.valorReceita.$touch()' />
            </div>
            <div class='field col-3'>
                <BInputNumeric v-model='valorContrapartidaFinanceira'
                               :desabilita='desabilita'
                               :mensagemErro="v$.valorContrapartidaFinanceira.$silentErrors[0]?.$message || ''"
                               :temErro='v$.valorContrapartidaFinanceira.$error'
                               label='Contrapartida Financeira'
                               required @blur='v$.valorContrapartidaFinanceira.$touch()' />
            </div>
            <div class='field col-3'>
                <BInputNumeric v-model='valorContrapartidaEconomica'
                               :desabilita='desabilita'
                               :mensagemErro="v$.valorContrapartidaEconomica.$silentErrors[0]?.$message || ''"
                               :temErro='v$.valorContrapartidaEconomica.$error'
                               label='Contrapartida Econômica'
                               required @blur='v$.valorContrapartidaEconomica.$touch()' />
            </div>
            <div class='field col-3'>
                <BInputNumeric v-model='valorAporteSebrae'
                               :desabilita='desabilita'
                               :mensagemErro="v$.valorAporteSebrae.$silentErrors[0]?.$message || ''"
                               :temErro='v$.valorAporteSebrae.$error'
                               label='Aporte Sebrae'
                               required @blur='v$.valorAporteSebrae.$touch()' />
            </div>
        </div>

        <br />

        <div class='flex'>
            <h5>Perfis</h5>
        </div>
        <div class='formgrid grid p-fluid'>
            <div class='field col-3'>
                <label for='accept'>Aproxima o Sebrae do Público-Alvo pretendido?</label>
            </div>
            <div class='field col-1'>
                <Checkbox v-model='aproximaSebraePublicoPretendido' :binary='true' :disabled='desabilita' />
            </div>
            <div class='field col-3'>
                <label for='accept'>Agrega Expertise Técnica?</label>
            </div>
            <div class='field col-1'>
                <Checkbox v-model='agregaExpertiseTecnica' :binary='true' :disabled='desabilita' />
            </div>
            <div class='field col-3'>
                <label for='accept'>Representação Setorial e Empresarial?</label>
            </div>
            <div class='field col-1'>
                <Checkbox v-model='representacaoSetorialEmpresarial' :binary='true' :disabled='desabilita' />
            </div>
            <div class='field col-3'>
                <label for='accept'>Promove crédito para as empresas?</label>
            </div>
            <div class='field col-1'>
                <Checkbox v-model='promoveCreditoParaEmpresas' :binary='true' :disabled='desabilita' />
            </div>
            <div class='field col-3'>
                <label for='accept'>Promove Mercado para os Pequenos Negócios?</label>
            </div>
            <div class='field col-1'>
                <Checkbox v-model='promoveMercadoPequenosNegocios' :binary='true' :disabled='desabilita' />
            </div>
            <div class='field col-3'>
                <label for='accept'>Tem foco em Inovação ou Cliente do Futuro?</label>
            </div>
            <div class='field col-1'>
                <Checkbox v-model='temFocoInovacaoEmpreendedorismo' :binary='true' :disabled='desabilita' />
            </div>
            <div class='field col-3'>
                <label for='accept'>Conexão Corporativa com Empresa de Grande Porte ou GOV?</label>
            </div>
            <div class='field col-1'>
                <Checkbox v-model='conexaoCorporativaEmpresaGrandePorteGov' :binary='true' :disabled='desabilita' />
            </div>
        </div>

        <div class='flex flex-wrap gap-3'>
            <div class='flex-1'></div>
            <Button style='margin-right: 10px' @click='$router.back()'>Voltar</Button>
            <Button :disabled='v$.$invalid' @click='onSubmit()'>Continuar</Button>
        </div>
    </LayoutEtapas>
</template>
<script>
import LayoutEtapas from './LayoutEtapas.vue';
import Services from '../../../service';
import { useVuelidate } from '@vuelidate/core';
import { helpers, minValue, required } from '@vuelidate/validators';

export default {
    components: {
        LayoutEtapas,
    },
    setup() {
        return {
            v$: useVuelidate({}),
        };
    },
    validations() {
        return {
            valorReceita: {
                required: helpers.withMessage('Campo obrigatório', required),
                minValue: helpers.withMessage('Valor mínimo é R$ 0,00', minValue(0)),
            },
            valorContrapartidaFinanceira: {
                required: helpers.withMessage('Campo obrigatório', required),
                minValue: helpers.withMessage('Valor mínimo é R$ 0,00', minValue(0)),
            },
            valorContrapartidaEconomica: {
                required: helpers.withMessage('Campo obrigatório', required),
                minValue: helpers.withMessage('Valor mínimo é R$ 0,00', minValue(0)),
            },
            valorAporteSebrae: {
                required: helpers.withMessage('Campo obrigatório', required),
                minValue: helpers.withMessage('Valor mínimo é R$ 0,00', minValue(0)),
            },
        };
    },
    data() {
        return {
            desabilita: false,
            valorReceita: '',
            valorContrapartidaFinanceira: '',
            valorContrapartidaEconomica: '',
            valorAporteSebrae: '',
            aproximaSebraePublicoPretendido: false,
            agregaExpertiseTecnica: false,
            representacaoSetorialEmpresarial: false,
            promoveCreditoParaEmpresas: false,
            promoveMercadoPequenosNegocios: false,
            temFocoInovacaoEmpreendedorismo: false,
            conexaoCorporativaEmpresaGrandePorteGov: false,
        };
    },
    methods: {
        async carregarDados() {
            const id = this.$route.params.id;
            this.$store.dispatch('addRequest');
            Services.SelectByIdParcerias(id)
                .then((response) => {
                    if (response && response.success && response.data) {
                        this.valorReceita = response.data.valorReceita;
                        this.valorContrapartidaFinanceira = response.data.valorContrapartidaFinanceira;
                        this.valorContrapartidaEconomica = response.data.valorContrapartidaEconomica;
                        this.valorAporteSebrae = response.data.valorAporteSebrae;
                        this.aproximaSebraePublicoPretendido = !!response.data.aproximaSebraePublicoPretendido;
                        this.agregaExpertiseTecnica = !!response.data.agregaExpertiseTecnica;
                        this.representacaoSetorialEmpresarial = !!response.data.representacaoSetorialEmpresarial;
                        this.promoveCreditoParaEmpresas = !!response.data.promoveCreditoParaEmpresas;
                        this.promoveMercadoPequenosNegocios = !!response.data.promoveMercadoPequenosNegocios;
                        this.temFocoInovacaoEmpreendedorismo = !!response.data.temFocoInovacaoEmpreendedorismo;
                        this.conexaoCorporativaEmpresaGrandePorteGov = !!response.data.conexaoCorporativaEmpresaGrandePorteGov;
                        this.desabilita = response.data.status !== 0 && response.data.status !== 1;
                    }
                })
                .finally(() => {
                    this.$store.dispatch('removeRequest');
                });
        },
        async onSubmit() {
            const id = this.$route.params.id;
            this.$store.dispatch('addRequest');
            const data = {
                valorReceita: this.valorReceita,
                valorContrapartidaFinanceira: this.valorContrapartidaFinanceira,
                valorContrapartidaEconomica: this.valorContrapartidaEconomica,
                valorAporteSebrae: this.valorAporteSebrae,
                aproximaSebraePublicoPretendido: this.aproximaSebraePublicoPretendido,
                agregaExpertiseTecnica: this.agregaExpertiseTecnica,
                representacaoSetorialEmpresarial: this.representacaoSetorialEmpresarial,
                promoveCreditoParaEmpresas: this.promoveCreditoParaEmpresas,
                promoveMercadoPequenosNegocios: this.promoveMercadoPequenosNegocios,
                temFocoInovacaoEmpreendedorismo: this.temFocoInovacaoEmpreendedorismo,
                conexaoCorporativaEmpresaGrandePorteGov: this.conexaoCorporativaEmpresaGrandePorteGov,
            };
            Services.AlterarAcordoDeParceriaValores(id, data)
                .then((response) => {
                    if (response && response.success && response.data) {
                        this.$router.push({
                            name: 'MapaParceiros_Parceria_Listagem',
                        });
                    }
                })
                .finally(() => {
                    this.$store.dispatch('removeRequest');
                });
        },
    },
    mounted() {
        if (!this.$route.params.id) {
            this.$router.replace({
                name: 'MapaParceiros_Parceria_Formulario_DadosGerais',
            });
        } else {
            this.carregarDados();
        }
    },
};
</script>